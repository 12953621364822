<template lang="html">
  <div class="form-container text-center mx-auto">
    <div class="main-cover main-cover__promi">
      <img
        class="img-fluid mx-auto d-block mt-4"
        src="@/assets/img/Cute_fire_small_size.png"
        alt="cute fire"
      />
    </div>
    <h1 class="header__text">{{ localizedTextFct("general", "welcome") }}</h1>
    <router-link v-if="first_message_type == 'error'" :to="{ name: 'register' }">{{
      localizedTextFct("register", "registration")
    }}</router-link>
    <router-link
      v-if="first_message_type == 'success' || first_message_type == 'info'"
      :to="{ name: 'login' }"
      >{{ localizedTextFct("login", "login") }}</router-link
    >
    <MessageBox :messages="messages" :message_type="message_type" />
  </div>
</template>

<script>
//import services
import AuthService from "@/services/AuthService.js";

//import components
import MessageBox from "@/components/common/MessageBox.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  name: "Verify",
  metaInfo: {
    title: "Verify email",
  },
  mixins: [handleServerMessage],
  components: {
    MessageBox,
  },
  data() {
    return {
      messages: [],
      message_type: [],
      first_message_type: "",
      first_message: true,
    };
  },
  mounted() {
    AuthService.verify(this.$route.query.id, this.$route.query.email)
      .then((res) => {
        if (this.first_message) {
          this.first_message_type = res.data.type;
          this.first_message = false;
        }
        this.handleServerMessage(res, "res", 3000);
      })
      .catch((err) => {
        if (this.first_message) {
          this.first_message_type = "error";
          this.first_message = false;
        }
        this.handleServerMessage(err, "err", 5000);
      });
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";

.form-container {
  max-width: 500px;
}
.header__text {
  color: $lightBlueHeader;
}
</style>
