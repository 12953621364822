<template lang="html">
  <nav class="navbar navbar-expand-sm navbar-light">
    <!-- Brand/logo -->
    <router-link :to="'/' + $i18n.locale + '/'" class="nav-link">
      <img src="@/assets/img/Logo_40.png" alt="logo" />
    </router-link>

    <!-- Toggler/collapsibe Button -->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#collapsibleNavbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav my_ul">
        <li
          class="nav-item"
          v-for="header in header_buttons"
          :key="header.link"
          :data-toggle="windowWidth < 1200 ? 'collapse' : ''"
          data-target="#collapsibleNavbar"
        >
          <router-link :to="'/' + $i18n.locale + header.link" class="nav-link">{{
            localizedTextFct("navbar", header.name)
          }}</router-link>
        </li>
        <li class="nav-item ml-sm-auto">
          <div class="right-container--visitor">
            <div
              class="locale-switcher-container"
              :data-toggle="windowWidth < 576 ? 'collapse' : ''"
              data-target="#collapsibleNavbar"
            >
              <LocaleSwitcher :visitor_page="true" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
//import mixins
import windowSize from "@/mixins/windowSize.js";
//import components
import LocaleSwitcher from "@/components/common/LocaleSwitcher";
export default {
  name: "VisitorsNavBar",
  mixins: [windowSize],
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      header_buttons: [
        {
          link: "/",
          name: "prometheus",
        },
        // {
        //   link: "/users/english",
        //   name: "english_course",
        // },
        {
          link: "/users/register",
          name: "register",
        },
        {
          link: "/users/login",
          name: "login",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/NavBar.scss";
</style>
