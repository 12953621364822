<template lang="html">
  <div class="container-fluid">
    <div class="main-text">
      <div class="text-center">
        <h1>Promi</h1>
      </div>
      <div class="row">
        <div class="col-6 text-center">
          <h3>Learn anything</h3>
        </div>
        <div class="col-6 text-center">
          <h3>Teach anything</h3>
        </div>
      </div>
    </div>
    <div class="main-cover main-cover__promi">
      <img
        class="img-fluid mx-auto d-block mt-4"
        src="@/assets/img/Cute_fire_small_size.png"
        alt="cute fire"
      />
    </div>
    <div class="text-center begin">
      <button class="my-router-link" @click="regRed">
        {{ localizedTextFct("buttons", "begin") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  metaInfo: {
    title: "Index",
  },
  methods: {
    regRed() {
      this.$router.push({ name: "register" });
    },
  },
};
</script>

<style lang="scss" scoped>
$buttonBgColor: rgb(60, 123, 255);
$buttonBorderColor: rgb(27, 101, 255);
$buttonTextColor: white;
$buttonBgColorHover: rgb(0, 57, 177);
$buttonBorderColorHover: rgb(27, 101, 255);
$buttonTextColorHover: white;
.begin {
  margin-top: 38px;
}
.my-router-link {
  width: 266px;
  height: 66px;
  border-radius: 33px;
  font-size: 30px;
  font-family: Arial;
  color: $buttonTextColor;
  border: 4px solid $buttonBorderColor;
  background-color: $buttonBgColor;
  outline: none;
}
.my-router-link:hover {
  text-decoration: none;
  color: $buttonTextColorHover;
  border: 4px solid $buttonBorderColorHover;
  background-color: $buttonBgColorHover;
}
</style>
