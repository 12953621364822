<template lang="html">
  <div class="container">
    <h2 class="text-center">{{ localizedTextFct("login", "login") }}</h2>
    <div class="container" id="form_container">
      <form id="login_form" autocomplete="on" @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">{{
            localizedTextFct("login", "username_or_email")
          }}</label>
          <input
            type="text"
            :placeholder="this.localizedTextFct('login', 'username')"
            name="username"
            id="username"
            class="form-control"
            autocomplete="username"
            required
            v-model="user.username"
            v-validate="'required'"
          />
          <div v-if="errors.has('username')" class="alert alert-danger" role="alert">
            {{ localizedTextFct("login", "username_required") }}
          </div>
        </div>

        <div class="form-group">
          <label for="password">{{ localizedTextFct("login", "password") }}</label>
          <input
            type="password"
            :placeholder="this.localizedTextFct('login', 'password')"
            name="password"
            id="password"
            class="form-control"
            autocomplete="current-password"
            required
            v-model="user.password"
            v-validate="'required'"
          />
          <div v-if="errors.has('password')" class="alert alert-danger" role="alert">
            {{ localizedTextFct("login", "password_required") }}
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="loading" id="log_btn">
            {{ this.localizedTextFct("buttons", "login") }}
          </button>
        </div>

        <MessageBox :messages="messages" :message_type="message_type" />
      </form>
    </div>
    <div class="container" id="signin">
      <!-- <p class='questionAccount'>Forgot your username or password? <a href="users/login" class='questionLink'>Retrieve</a>.</p> -->
      <p class="questionAccount">
        {{ this.localizedTextFct("login", "new_user") + " "
        }}<router-link :to="{ name: 'register' }">{{
          this.localizedTextFct("login", "register")
        }}</router-link
        >.
      </p>
      <p class="questionAccount">
        {{ this.localizedTextFct("login", "forgot_password") + " "
        }}<router-link :to="{ name: 'forgot' }">{{
          this.localizedTextFct("login", "forgot")
        }}</router-link
        >.
      </p>
    </div>
  </div>
</template>

<script>
//import models
import User from "@/models/user.js";

//import components
import MessageBox from "@/components/common/MessageBox.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  name: "Login",
  metaInfo: {
    title: "Login",
  },
  mixins: [handleServerMessage],
  components: {
    MessageBox,
  },
  data() {
    return {
      user: new User("", ""),
      messages: [],
      loading: false,
      message_type: [],
    };
  },
  methods: {
    handleLogin() {
      let self = this;
      self.loading = true;
      self.$validator.validateAll().then(function (isValid) {
        if (!isValid) {
          self.loading = false;
          return;
        }

        if (self.user.username && self.user.password) {
          self.$store
            .dispatch("auth/login", self.user)
            .then((user) => {
              if (user.user_type == "student") {
                window.location.href = `/students/${self.$i18n.locale}/homepage`;
              } else if (user.user_type == "teacher") {
                window.location.href = `/teachers/${self.$i18n.locale}/homepage`;
              }
            })
            .catch((err) => {
              self.handleServerMessage(err, "err", 5000);
              self.handleServerMessageNotify(err, "err", 5000);

              self.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#form_container,
#signin {
  max-width: 500px;
}

#add_fields,
#log_btn {
  width: 100%;
}
</style>
