<template lang="html">
  <div>
    <a href="https://www.freeprivacypolicy.com/live/094b03cc-5b8d-455b-8a7d-49cb73d9c33d"
      >Check Privacy Policy</a
    >
  </div>
</template>
<script>
export default {
  name: "privacy_policy",
  metaInfo: {
    title: "Privacy policy",
  },
};
</script>
<style lang="scss" scoped></style>
