<template lang="html">
  <div class="container">
    <h2 class="text-center">{{ this.localizedTextFct("recover", "recover") }}</h2>
    <div class="container" id="form_container">
      <form id="login_form" autocomplete="on" @submit.prevent="handleForgot">
        <div class="form-group">
          <label for="email">{{ this.localizedTextFct("recover", "email") + ":" }}</label>
          <input
            type="text"
            :placeholder="this.localizedTextFct('recover', 'email_address')"
            name="email"
            id="email"
            class="form-control"
            autocomplete="email"
            required
            maxlength="45"
            v-model="email"
            v-validate="'required|email|max:45'"
          />
          <div v-if="errors.has('email')" class="alert alert-danger" role="alert">
            Wrong email format!
          </div>
        </div>

        <div class="form-group">
          <button
            type="submit"
            class="btn btn-primary auth-button"
            :disabled="loading"
            id="forgot_btn"
          >
            {{ this.localizedTextFct("recover", this.retrieve_button) }}
          </button>
        </div>

        <MessageBox :messages="messages" :message_type="message_type" />
      </form>
    </div>
  </div>
</template>

<script>
//import components
import MessageBox from "@/components/common/MessageBox.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  name: "Forgot",
  metaInfo: {
    title: "Forgot",
  },
  components: {
    MessageBox,
  },
  mixins: [handleServerMessage],
  data() {
    return {
      email: "",
      messages: [],
      loading: false,
      message_type: [],
      retrieve_button: "retrieve",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },

  methods: {
    handleForgot() {
      let self = this;
      self.messages = [];
      self.loading = true;
      self.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          self.loading = false;
          return;
        }

        if (self.email) {
          self.$store
            .dispatch("auth/forgot", self.email)
            .then((res) => {
              this.handleServerMessage(res, "res", 3000);
              self.loading = false;
            })
            .catch((err) => {
              this.handleServerMessage(err, "err", 5000);
              self.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#form_container {
  max-width: 500px;
}

#add_fields,
#log_btn {
  width: 100%;
}
.auth-button {
  width: 100%;
}
</style>
