import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import Index from "@/components/visitors/Index.vue";
// import English from "@/components/visitors/English.vue";
import Register from "@/components/visitors/Register.vue";
import Forgot from "@/components/visitors/Forgot.vue";
import Verify from "@/components/visitors/Verify.vue";
import Reset from "@/components/visitors/Reset.vue";
import Login from "@/components/visitors/Login.vue";

import TermsOfService from "@/components/visitors/TermsOfService.vue";

import PrivacyPolicy from "@/components/visitors/PrivacyPolicy.vue";

import AdminLogin from "@/components/visitors/AdminLogin.vue";


//ATTENTION BE CAREFULL HERE WITH CIRCLE REFERENCES POTENTIOAL MEMORY LEAK
import RouterView from "@/components/common/RouterView.vue";

import { Trans } from '@/plugins/Translation';


export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/privacy_policy",
      name: "privacy_policy",
      component: PrivacyPolicy
    },
    {
      path: "/terms_of_service",
      name: "terms_of_service",
      component: TermsOfService
    },
    {
      path: "/",
      redirect: Trans.currentLocale
    },
    {
      path: "/:locale",
      component: RouterView,
      beforeEnter: Trans.routeMiddleware,
      children: [
        {
          path: "",
          redirect: "/:locale/index"
        },
        {
          path: "index",
          name: "index",
          component: Index
        },
        // {
        //   path: "users/english",
        //   name: "english",
        //   component: English
        // },
        {
          path: "users/register",
          name: "register",
          component: Register
        },
        {
          path: "users/forgot",
          name: "forgot",
          component: Forgot
        },
        {
          path: "users/verify",
          name: "verify",
          component: Verify
        },
        {
          path: "users/reset/:token",
          name: "reset",
          component: Reset
        },
        {
          path: "users/login",
          name: "login",
          component: Login
        },
        {
          path: "users/admins/login",
          name: "admin_login",
          component: AdminLogin
        },
      ]
    },
  ]
});

router.beforeEach((to, from, next) => {
  const publicPagesNames = ['index', 'english', 'login', 'register', 'forgot', 'reset', 'verify', 'admin_login', 'privacy_policy', 'terms_of_service'];
  const loggedIn = localStorage.getItem('user');

  const authRequired = !publicPagesNames.includes(to.name);

  // trying to access a restricted page + not logged in
  // redirect to login page

  if (authRequired && !loggedIn) {
    next({name: 'login'});
  }
  else {
    next();
  }

});
