<template lang="html">
  <div class="container">
    <h2 class="text-center">Password Reset</h2>
    <div class="container" id="form_container">
      <form id="login_form" autocomplete="on" @submit.prevent="handleReset">
        <div class="form-group">
          <label for="password">New Password:</label>
          <input
            type="password"
            placeholder="Password"
            name="password"
            id="password"
            class="form-control"
            autocomplete="new-password"
            required
            v-model="user.password"
            ref="password"
            v-validate="'required|min:8|max:30'"
          />
          <div v-if="errors.has('password')" class="alert-danger">
            {{ errors.first("password") }}
          </div>
        </div>

        <div class="form-group">
          <label for="password2">{{
            this.localizedTextFct("register", "repeat_password")
          }}</label>
          <input
            type="password"
            placeholder="Repeat Password"
            name="password2"
            id="password2"
            class="form-control"
            autocomplete="new-password"
            required
            v-model="user.password2"
            v-validate="'required|min:8|max:30|confirmed:password'"
          />
          <div v-if="errors.has('password2')" class="alert-danger">
            {{ errors.first("password2") }}
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary" :disabled="loading" id="log_btn">
            Reset
          </button>
        </div>

        <div class="form-group text-center">
          <router-link
            v-if="first_message_type == 'success' || first_message_type == 'info'"
            :to="{ name: 'login' }"
            >{{ this.localizedTextFct("login", "login") }}</router-link
          >
        </div>

        <MessageBox :messages="messages" :message_type="message_type" />
      </form>
    </div>
  </div>
</template>

<script>
//import services
import AuthService from "@/services/AuthService.js";

//import components
import MessageBox from "@/components/common/MessageBox.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

export default {
  name: "Reset",
  metaInfo: {
    title: "Reset password",
  },
  mixins: [handleServerMessage],
  components: {
    MessageBox,
  },
  data() {
    return {
      user: {
        password: "",
        password2: "",
      },
      messages: [],
      loading: false,
      message_type: [],
      first_message_type: "",
      first_message: true,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },

  methods: {
    handleReset() {
      let self = this;
      self.loading = true;
      self.$validator.validate().then(function (isValid) {
        if (!isValid) {
          self.loading = false;
          return;
        }
        if (self.user.password && self.user.password2) {
          AuthService.reset(self.user, self.$route.params.token)
            .then((res) => {
              if (self.first_message) {
                self.first_message_type = res.data.type;
                self.first_message = false;
              }
              self.handleServerMessage(res, "res", 3000);

              self.loading = false;
            })
            .catch((err) => {
              if (self.first_message) {
                self.first_message_type = "error";
                self.first_message = false;
              }
              self.handleServerMessage(err, "err", 5000);
              self.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
#form_container {
  max-width: 500px;
}

#add_fields,
#log_btn {
  width: 100%;
}
</style>
